import {
  DecimalPipe,
  NgClass,
  NgFor,
  NgIf,
  NgTemplateOutlet,
  UpperCasePipe,
} from '@angular/common';
import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { Router } from '@angular/router';
import { country } from '@features/country/data/services/country.service';
import { MerchantTestableProductRelationModel } from '@features/locked-testable-products/data';
import { LockedTestableProductsUseCaseResolver } from '@features/locked-testable-products/domain';
import { user } from '@features/user/data';
import { TranslateModule } from '@ngx-translate/core';
import { navigateTo } from '@presentation/shared/router';
import { scrollTo } from '@presentation/shared/utils';
import { LocalizedComponent } from 'app/presentation/base/localized.component';
import { OnHoverTooltipComponent } from 'app/presentation/shared/components/on-hover-tooltip/on-hover-tooltip.component';
import {
  Analytics,
  BundleVariantModel,
  ProductAvailability,
  ProductBasicInfo,
  ProductVariantsData,
  Variant,
  VariantSalesModel,
} from 'src/app/core/domain/products/product-detail.model';
import { GetFeatureAttributeUsecase } from 'src/app/core/usecases/get-feature-attribute.usecase';
import { GetFeatureFlagUsecase } from 'src/app/core/usecases/get-feature-flag.usecase';
import { CheckUserFeatureExistsUseCase } from 'src/app/core/usecases/user/check-user-feature-exists.usecase';
import {
  CPA_CALCULATOR_USER_FEATURE,
  PRE_ORDER_REQUESTS_URL,
  PRODUCT_DETAILS,
} from 'src/app/presentation/shared/constants';
import {
  CPA_CALCULATOR,
  MARKETPLACE_V3_2_USER_FEATURE,
  STOCK_DISTRIBUTION,
  WEB_MARKETPLACE_V3_2,
  WEB_MARKETPLACE_V3_3,
  WEB_MARKETPLACE_V3_4,
} from 'src/app/presentation/shared/constants/feature-flags';
import { featureAttributeAssign } from 'src/app/presentation/shared/utilities/feature-attribute-assign.utility';
import { CurrencyShortNamePipe } from '../../../shared/pipes/currency-short-name.pipe';
import { CurrencyTranslatePipe } from '../../../shared/pipes/currency-translate.pipe';
import { ProductLockExtensionPopupComponent } from '../product-actions/product-lock-extension-popup/product-lock-extension-popup.component';
import { CpaCalculatorComponent } from './cpa-calculator/cpa-calculator.component';
import { ProductQuantityOffersComponent } from './product-quantity-offers/product-quantity-offers.component';
import { ProductVariantsComponent } from './product-variants/product-variants.component';

@Component({
  selector: 'app-product-basic-info',
  templateUrl: './product-details-basic-info.component.html',
  styleUrls: ['./product-details-basic-info.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgTemplateOutlet,
    NgClass,
    DecimalPipe,
    TranslateModule,
    CurrencyShortNamePipe,
    CurrencyTranslatePipe,
    UpperCasePipe,
    ProductQuantityOffersComponent,
    CpaCalculatorComponent,
    ProductVariantsComponent,
    MatProgressBarModule,
    NgFor,
    OnHoverTooltipComponent,
  ],
})
export class ProductDetailsBasicInfoComponent extends LocalizedComponent implements OnInit {
  @Input() basicInfo: ProductBasicInfo;

  @Input() isMarketplaceEnabled: boolean;

  @Input() saleInfo: VariantSalesModel;

  @Input() currentProduct: Variant;

  @Input() flashSaleTitle: string;

  @Input() userHasStore: boolean;

  @Input() storeProvider: string;

  @Input() productAddedToStore: boolean;

  @Input() userHasStockAvailability: boolean;

  @Input() showStockAvailability: boolean;

  @Input() stockAvailabilityStatus: string;

  @Input() stockAvailabilityRunRate: string;

  @Input() productAvailability: ProductAvailability;

  @Input() productIsOrderable: boolean;

  @Input() isDiscountAsSecondProduct: boolean;

  @Input() isPriceRangeEnabled: boolean;

  @Input() skuAnalyticsEnabled: boolean;

  @Input() skuAnalytics: Analytics;

  @Input() isDiscountOnQuantityEnabled: boolean;

  @Input() variantsData: ProductVariantsData;

  @Output() variantSelected: EventEmitter<{ color?: string; size?: string }> = new EventEmitter();

  @Output() addToCart: EventEmitter<void> = new EventEmitter();

  @Output() orderNow: EventEmitter<number> = new EventEmitter();

  @Output() addProductToStore: EventEmitter<void> = new EventEmitter();

  public productAvailabilityEnum = ProductAvailability;

  public shouldShowStockDistribution = false;

  public remainingDays?: number;

  public remainingHours?: number;

  public assetsPath = 'assets/img/';

  public storesAssetsPath = 'assets/img/stores/';

  public isUserEligibleForCPA = false;

  private _getFeatureFlagUseCase = inject(GetFeatureFlagUsecase);

  private _getFeatureAttributeUseCase = inject(GetFeatureAttributeUsecase);

  private _checkUserFeatureExistsUseCase = inject(CheckUserFeatureExistsUseCase);

  public stockIsExpired = false;

  public stockExpiryType: 'time' | 'quantity';

  public shouldShowMarketplaceV3_2 = false;

  public shouldShowMarketplaceV3_4 = false;

  public shouldShowAvailableTestableQuantity = false;

  public merchantTestableProductRelationData: MerchantTestableProductRelationModel;

  public marketplaceV3_3Enabled = false;

  public minQuantityToEnableLocking: number;

  public tooltipStyles = {
    insetInlineEnd: '-125px',
    textAlign: 'start',
    backgroundColor: '#13161F',
    width: 'max-content',
    maxWidth: '364px',
    bottom: '25px',
  };

  constructor(private router: Router, private _matDialog: MatDialog) {
    super();
  }

  ngOnInit(): void {
    this._getFeatureFlagUseCase.execute(CPA_CALCULATOR).subscribe((flag) => {
      const userHasCPACalculator = this._checkUserFeatureExistsUseCase.execute(
        CPA_CALCULATOR_USER_FEATURE,
      );
      const isCPAEnabled = flag;
      const isKsaSelected = country.is('KSA');
      this.isUserEligibleForCPA = isKsaSelected && isCPAEnabled && userHasCPACalculator;
    });
    this.isStockDistributionEnabled();

    this.isMarketplaceV3_2Enabled();

    this.isMarketplaceV3_3Enabled();

    this.isMarketplaceV3_4Enabled();

    this.calculateMinQuantityToEnableLocking();
  }

  public isStockDistributionEnabled(): void {
    this._getFeatureAttributeUseCase.execute(STOCK_DISTRIBUTION).subscribe((attribute) => {
      this.shouldShowStockDistribution = featureAttributeAssign(attribute, user.id);
      if (this.shouldShowStockDistribution) {
        this.calculateStockNumberOfDays();
      }
    });
  }

  private isMarketplaceV3_2Enabled(): void {
    this._getFeatureAttributeUseCase.execute(WEB_MARKETPLACE_V3_2).subscribe({
      next: (attribute) => {
        this.shouldShowMarketplaceV3_2 =
          featureAttributeAssign(attribute, user.id, country.code) &&
          user.features.includes(MARKETPLACE_V3_2_USER_FEATURE);

        if (this.shouldShowMarketplaceV3_2) {
          this.checkIfAllowedToTestLockedProduct();
        }
      },
    });
  }

  private isMarketplaceV3_4Enabled(): void {
    this._getFeatureAttributeUseCase.execute(WEB_MARKETPLACE_V3_4).subscribe({
      next: (attribute) => {
        this.shouldShowMarketplaceV3_4 = featureAttributeAssign(attribute, user.id, country.code);
      },
    });
  }

  private checkIfAllowedToTestLockedProduct(): void {
    LockedTestableProductsUseCaseResolver.merchantTestableProductRelation(
      this.currentProduct._id,
    ).then((res) => {
      this.shouldShowAvailableTestableQuantity =
        res.orderTestingAvailable && !res.orderTestingStarted;
      this.merchantTestableProductRelationData = res;
    });
  }

  public openExtensionForm(): void {
    this._matDialog.open(ProductLockExtensionPopupComponent, {
      width: '770px',
      direction: this.isLTR ? 'ltr' : 'rtl',
      autoFocus: false,
      data: {
        sku: this.currentProduct.prodID,
        productName: this.currentProduct.productName,
      },
    });
  }

  public calculateStockNumberOfDays(): void {
    if (this.currentProduct.availabilityInfo?.stockInfo?.expiresAt) {
      const currentDate = new Date();
      const expiryDate = new Date(this.currentProduct.availabilityInfo?.stockInfo?.expiresAt);
      if (expiryDate > currentDate) {
        this.remainingDays = Math.floor(
          (currentDate.getTime() - expiryDate.getTime()) / (1000 * 60 * 60 * 24),
        );
        this.remainingDays = Math.abs(this.remainingDays);
        if (this.remainingDays < 2) {
          const currentTime = currentDate.getTime();
          const expiryTime = expiryDate.getTime();
          const remainingTime = expiryTime - currentTime;
          if (remainingTime > 3600000) {
            const hoursDifference = remainingTime / (3600 * 1000);
            this.remainingHours = Math.floor(hoursDifference);
          } else {
            this.remainingHours = 1;
          }
        }
      } else {
        this.stockIsExpired = true;
        this.stockExpiryType = 'time';
        this.remainingDays = undefined;
        this.remainingHours = undefined;
      }
    }
    this.checkStockQuantityExpiry();
  }

  public checkStockQuantityExpiry(): void {
    if (this.currentProduct.availabilityInfo?.stockInfo?.remainingStock === 0) {
      this.stockIsExpired = true;
      this.stockExpiryType = 'quantity';
    }
  }

  onAddProductToStore(): void {
    this.addProductToStore.emit();
  }

  onAddToCart(): void {
    this.addToCart.emit();
  }

  onOrderNow(noOfItems: number): void {
    this.orderNow.emit(noOfItems);
  }

  onSelectVariant(variantSelected: { color: string; size: string }): void {
    const { color, size } = variantSelected;
    if (color) {
      this.variantSelected.emit({ color });
    } else if (size) {
      this.variantSelected.emit({ size });
    }
  }

  public navigateToPreOrderPage(): void {
    this.router.navigate([PRE_ORDER_REQUESTS_URL]);
  }

  public navigateToProduct(bundleVariant: BundleVariantModel): void {
    navigateTo(`${PRODUCT_DETAILS}/${bundleVariant._id}`);
  }

  private isMarketplaceV3_3Enabled(): void {
    this._getFeatureAttributeUseCase.execute(WEB_MARKETPLACE_V3_3).subscribe({
      next: (attribute) => {
        this.marketplaceV3_3Enabled = featureAttributeAssign(attribute, user.id, country.code);
      },
    });
  }

  private calculateMinQuantityToEnableLocking(): void {
    if (this.currentProduct.orderTestingConfig) {
      const { succeededTestingLockRate, maxTestingQuantity } =
        this.currentProduct.orderTestingConfig;

      this.minQuantityToEnableLocking = Math.round(succeededTestingLockRate * maxTestingQuantity);
    }
  }

  public scrollToLockingSection(): void {
    scrollTo('ordering-actions');
  }
}
